import { GLOBAL_CONSTANTS } from 'utils/constants'
/**
 * Functionality to toggle the revealing of the Greenhouse iFrame application form on the
 * Careers Detail page
 */

const CLASSES = {
    COMPONENT: '.js-greenhouse-iframe',
    GREENHOUSE_WRAPPER: '.js-greenhouse-iframe-wrapper',
    APPLY_BUTTON: '.js-apply-button'
}

export default class GreenhouseiFrame {
    /**
     * @desc Set up GreenhouseiFrame elements
     *
     */

    constructor(el) {
        this.el = el
        this.greenhouseWrapper = this.el.querySelector(CLASSES.GREENHOUSE_WRAPPER)
        this.applyButton = this.el.querySelector(CLASSES.APPLY_BUTTON)
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }


    /**
     * @desc Listen for click event on the Apply button
     */
    registerEvents () {
        this.applyButton.addEventListener('click', this.handleClick.bind(this))
    }

    /**
     * @desc Handles click by toggling reveal class
     */
    handleClick() {
        this.greenhouseWrapper.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

/**
 * @desc GreenhouseiFrame component definition used in module-loader
 */

export const GreenhouseiFrameComponent = {
    'name': 'GreenhouseiFrame',
    'class': CLASSES.COMPONENT,
    'Source': GreenhouseiFrame
}
