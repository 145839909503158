import { GLOBAL_CONSTANTS } from '../utils/constants'

const CLASSES = {
    COMPONENT: '.js-html5video',
    VIDEO: '.js-html5video-video',
    VIDEO_BUTTON: '.js-html5video-button'
}


export default class Video {
    /**
     * @desc Set up Video with elements and bind events.
     * @param {HTMLElement} el - Container element for Video
     *
     */

    constructor(element) {
        this.element = element
        this.video = element.querySelector(CLASSES.VIDEO)
        this.videoButton = element.querySelector(CLASSES.VIDEO_BUTTON)
        this.hasAudio = this.element.dataset.hasaudio ? JSON.parse(this.element.dataset.hasaudio) : false

        // disable right click on video elements
        this.video.addEventListener('contextmenu', e => e.preventDefault())

        //only enable if the video is ambient
        //otherwise rely on html5 controls
        if (!this.hasAudio) {
            this.registerListeners()
        }
    }

    registerListeners() {
        this.element.addEventListener('click', this.togglePlayPause.bind(this))
    }

    togglePlayPause() {
        if (!this.video.paused) {
            this.video.pause()
            this.videoButton.classList.remove(GLOBAL_CONSTANTS.CLASSES.PLAYING)
        } else {
            this.video.play()
            this.videoButton.classList.add(GLOBAL_CONSTANTS.CLASSES.PLAYING)
        }
    }
}




/**
 * @desc Test component definition used in module-loader
 */

export const VideoComponent = {
    'name': 'Video',
    'class': CLASSES.COMPONENT,
    'Source': Video
}
