import whatInput from 'what-input'

export function isMobile() {
    return window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.MOBILE_LG
}

export function isStandardTablet() {
    return window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET
}

export function isTablet() {
    return window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET_LG
}

export function isDesktop() {
    return window.innerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP
}

export function isTouchDevice() {
    return whatInput.ask() === 'touch'
}

const TIMING = {
    FAST_ANIM_TIME: 250, //this should be kept in parity with the CSS $anim_duration_fast variable
    STD_ANIM_TIME: 600, //this should be kept in parity with the CSS $anim_duration variable
    NAV_SCROLL_THROTTLE: 10,
    STANDARD_THROTTLE: 150,
    RESIZE_THROTTLE: 100,
    MODAL_ANIMATION_DURATION: 300,
    CAROUSEL_ANIMATION_DURATION: 8000
}

export const GLOBAL_CONSTANTS = {
    EVENTS: {
        SCROLL: 'scroll',
        RESIZE: 'resize',
        ORIENTATION_CHANGE: 'orientation-change',
        FOCUS: 'focus',
        BLUR: 'blur',
        CHANGE: 'change',
        KEYDOWN: 'keydown',
        LAZY_LOADED: 'lazyloaded',
        LOAD: 'load',
        FORM_SUBMIT: 'form-submit',
        MODAL_OPEN: 'modal-open',
        CLOSE_MOBILE_MENU: 'close-mobile-menu',
        SHOW_TABLE_TOP: 'show-table-top',
        HOMEPAGE_CAROUSEL_INIT: 'homepage-carousel-init',
        HOMEPAGE_CAROUSEL_SLIDE_CHANGE: 'homepage-carousel-slide-change',
        MOBILE_NAV_OPEN: 'mobile-nav-open',
        HIDE_SHY_NAV: 'hide-shy-nav',
        GET_IMAGE_DRAWER_HEIGHT: 'get-image-drawer-height'
    },
    CLASSES: {
        ACTIVE: '-active',
        LOADED: '-loaded',
        FIXED: '-fixed',
        HOVER: '-hover',
        SHOW: '-show',
        INACTIVE: '-inactive',
        STATIC: '-static',
        HIDDEN: '-hidden',
        VISUALLY_HIDDEN: '-visually-hidden',
        PLAYING: '-playing',
        OPAQUE: '-opaque',
        VISIBLE: '-visible',
        TRANSPARENT: '-transparent',
        PINNED: '-pinned',
        IS_CLOSED: '-is-closed',
        FADE: '-fade',
        STICKY: '-sticky',
        BG_COLOR_SUFFIX: '--has-bg-color'
    },
    BREAKPOINTS: {
        MOBILE: 320,
        MOBILE_LG: 375,
        TABLET: 768,
        TABLET_LG: 1024,
        DESKTOP: 1280,
        DESKTOP_LG: 1440,
        DESKTOP_HEIGHT_SM: 801
    },
    KEYS: {
        ESC: 27
    },
    TABLE_TOP: {
        MOBILE_BLOCK_LENGTH: 9
    },
    TIMING,
    YOUTUBE_API_URL: 'https://www.youtube.com/iframe_api',
    HOTJAR_AGENT: 'Hotjar'
}
