import initRollbar from 'utils/rollbar'
import 'what-input'
import { modules } from 'utils/module-loader'

import Emitter from 'utils/emitter'
import isLoaded from 'utils/isLoaded'
import LazyLoad from 'utils/LazyLoad'
import { getScrollbarWidth } from 'utils/scrollUtil'

export default class App {
    constructor() {
        this.detectJs()
        if (DEPLOYMENT_ENV !== 'local' && (window.location.origin.indexOf('eamesinstitute.org') > 0 || window.location.origin.indexOf('eames-inst.com') > 0)) {
            this.registerLogging()
        }
        this.registerEvents()
        this.onPageLoad()
    }

    /**
     * @desc Triggers all functions that need to run each page refresh.
     */
    onPageLoad() {
        this.instances = this.registerInstances()
        this.initLazyLoad()
        getScrollbarWidth()
    }

    /**
     * @desc Initilizes Lazyloading. Parses page for data references
     * and triggers fade in.
     */
    initLazyLoad() {
        this.LazyLoad = new LazyLoad()
    }

    /**
     * @desc If JS is available,
     * remove the noscript class from the body element.
     */
    detectJs() {
        document.body.classList.remove('noscript')
    }

    /**
     * @desc Create all instances of classes that are paired with
     * javascript components in the modules Array.
     * @return {Array.<Object>} Array of all module objects.
     */
    registerInstances() {
        return modules.map((module) => {
            const elements = Array.from(document.querySelectorAll(module.class))
            const references = elements.map(el => new module.Source(el))

            return {
                name: module.name,
                ref: references
            }
        })
    }

    registerLogging() {
        this.rollbar = initRollbar()
        /** Register Rollbar error handler */
        Emitter.on('error', ({ message = '', stacktrace = [] }) => {
            if (!window.localProxy) {
                this.rollbar.error(message, stacktrace)
            }
        })
        /** Register Rollbar error handler */
        Emitter.on('exception', ({ error = '', req = '' }) => {
            if (!window.localProxy) {
                this.rollbar.critical(error, req)
            }
        })
    }

    /**
     * @desc - These events get instantiated once and bound to the window
     * so that there aren’t multiple events firing from each page module
     * @fires <App>#[load]
     * @fires <App>#[resize]
     * @fires <App>#[scroll]
     * @fires <App>#[keydown]
     */
    registerEvents() {
        /** Fire load event to emitter on page load */
        window.addEventListener('load', (e) => {
            Emitter.emit('load', e)
        })
        /** Fire resize event to emitter when window resizes */
        window.addEventListener('resize', () => {
            Emitter.emit('resize')
        })
        /** Fire scroll event to emitter when window scroll */
        window.addEventListener('scroll', () => {
            Emitter.emit('scroll')
        })
        /** Fire keydown event to emitter on window keydown */
        window.addEventListener('keydown', (e) => {
            Emitter.emit('keydown', e)
        })
    }
}

isLoaded(App)
