import Emitter from 'utils/emitter'
import {
    GLOBAL_CONSTANTS,
    isTouchDevice
} from 'utils/constants'

const CLASSES = {
    CURSOR: '.cursor',
    IMAGE_BASE: '.image-base',
    IMAGE_ZOOM_CONTAINER: 'img-zoom-container',
    IMAGE_ZOOM_LENS: 'img-zoom-lens',
    ZOOM_RESULT: 'zoom-result',
    ZOOM_DISABLED: 'zoom-disabled',
    INSTRUCTION_TEXT: '.artifact-page__instruction-text'
}

export default class ImageZoom {
    constructor(element) {
        this.container = element
        this.image = this.container.querySelector(CLASSES.IMAGE_BASE)
        this.container.classList.add(CLASSES.IMAGE_ZOOM_CONTAINER)
        this.referencePoint = [0, 0]
        this.isZoomActive = false
        this.zoomEventsReady = false
        this.zoomDisabled = this.image.classList.contains(CLASSES.ZOOM_DISABLED) || false
        this.mobileTimeout = null
        this.isLongpress = false
        this.pressTimer
        this.zoomOffset = 15
        this.pressDuration = 300 // time in ms for press to trigger a "longpress"

        this.init()
    }

    init() {
        this.zoom = this.container.dataset.zoom || 3.5
        this.zoomRadius = this.container.dataset.zoomRadius || 300
        this.lensSize = this.zoomRadius / this.zoom
        this.objectContain = this.container.dataset.objectContain || false

        //set the zoom container on load & resize
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.LAZY_LOADED, (data) => {
            this.setZoomContainerSize(data)
        })
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.setZoomContainerSize.bind(this))

        // if zoom disabled, hide instruction text
        if (isTouchDevice() && this.zoomDisabled) {
            const instructionText = document.querySelector(CLASSES.INSTRUCTION_TEXT)
            instructionText.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
            instructionText.setAttribute('aria-hidden', 'true')
        }

        // Touch-events fire differently than their mouse counterparts, so the
        // way the handle methods are called needs to change slightly for touch-
        // based devices
        if (isTouchDevice() && !this.zoomDisabled) {
            this.image.addEventListener('touchmove', (e) => {
                if (this.isLongpress || this.isZoomActive) {
                    e.preventDefault()
                }
            })
            this.image.addEventListener('touchstart', () => {
                this.pressTimer = setTimeout(() => {
                    this.isLongpress = true
                    this.handleMouseOver()
                    this.handleImageHover()
                }, this.pressDuration)
            })
            this.image.addEventListener('touchend', (e) => {
                clearTimeout(this.pressTimer)
                if (this.isLongpress) {
                    this.handleMouseLeave(e.type)
                    this.isLongpress = false
                }
            })
        } else {
            this.image.addEventListener('mouseenter', () => this.handleMouseOver())
            this.image.addEventListener('mouseover', () => this.handleImageHover())
            this.image.addEventListener('mouseleave', () => this.handleMouseLeave())
        }

        this.result = this.container.querySelector(CLASSES.CURSOR)
    }

    handleMouseLeave(evtType) {
        // This timeout only fires for touch-devices. It does two things:
        // 1. Auto-hides the zoom lens after a set period, which functions more
        //    similarly to the experience with a mouse
        // 2. Facilitates the ability for the user to pick up their finger and
        //    then place it down again within the timeout window to continue the
        //    zoomed experience without having to re-fire all the setup methods
        this.mobileTimeout = setTimeout(() => {
            this.result.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.removeZoom()
            this.mobileTimeout = null
        }, evtType === 'touchend' ? 1500 : 0) // 1.5s delay on touch devices before the lens disappears after touchend
    }

    handleMouseOver() {
        this.result.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        // This emitter hides the shy nav. Function found in `Nav.js`.
        Emitter.emit(GLOBAL_CONSTANTS.EVENTS.HIDE_SHY_NAV)

        if (!this.mouseOverReady) {
            const moveInitialCursor = () => this.moveInitialCursor()
            this.image.addEventListener('mousemove', moveInitialCursor)
            this.mouseOverReady = true
        }
    }

    handleImageHover() {
        if (this.mobileTimeout !== null) {
            clearTimeout(this.mobileTimeout)
            this.mobileTimeout = null
            return
        }

        if (this.result.classList.contains(CLASSES.ZOOM_RESULT)) {
            this.removeZoom()
        } else {
            this.imageZoom()
            this.result.classList.add(CLASSES.ZOOM_RESULT)
        }
    }

    removeZoom() {
        const { top, left } = this.result.style

        this.result.removeAttribute('style')
        this.result.classList.remove(CLASSES.ZOOM_RESULT)

        if (this.lens.parentElement) {
            this.lens.parentElement.removeChild(this.lens)
        }

        this.result.style.top = top
        this.result.style.left = left
        this.isZoomActive = false
    }

    imageZoom() {
        let img, lens, imgWidth, imgHeight
        img = this.image

        this.result.style.width = `${this.zoomRadius}px`
        this.result.style.height = `${this.zoomRadius}px`

        /* Create lens: */
        this.lens = lens = document.createElement('DIV')
        lens.classList.add(CLASSES.IMAGE_ZOOM_LENS)
        lens.style.width = `${this.lensSize}px`
        lens.style.height = `${this.lensSize}px`

        /* Insert lens: */
        img.parentElement.insertBefore(lens, img)

        /* Set background properties for the result DIV */
        if (this.objectContain) {
            imgWidth = img.width
            imgHeight = img.naturalHeight * (img.width / img.naturalWidth)
        } else {
            imgWidth = img.width
            imgHeight = img.height
        }

        this.result.style.backgroundImage = `url('${img.src}')`
        this.result.style.backgroundSize = (imgWidth * this.zoom) + 'px ' + (imgHeight * this.zoom) + 'px'

        const moveLens = () => this.moveLens()
        if (!this.zoomEventsReady) {
            lens.addEventListener('mousemove', moveLens)
            img.addEventListener('mousemove', moveLens)
            lens.addEventListener('touchmove', moveLens)
            img.addEventListener('touchmove', moveLens)
            this.zoomEventsReady = true
        }

        this.isZoomActive = true
        moveLens()
    }

    moveLens(e) {
        /* Prevent any other actions that may occur when moving over the image */
        e && e.preventDefault()

        if (this.isZoomActive) {
            this.calculateReferencePoint()

            /* move lens to match cursor, but dont exceed image border */
            this.lens.style.left = this.referencePoint[0] + 'px'
            this.lens.style.top = this.referencePoint[1] + 'px'

            this.centerResultOnCursor()
            this.repositionZoomBackground()
        }
    }

    moveInitialCursor() {
        this.calculateReferencePoint()
        this.result.style.left = this.referencePoint[0] + 'px'
        this.result.style.top = this.referencePoint[1] + 'px'
    }

    calculateReferencePoint() {
        let pos, x, y
        let lens = {}

        if (this.lens) {
            lens = this.lens
        } else {
            lens.offsetWidth = 0
            lens.offsetHeight = 0
        }

        /* Get the cursor's x and y positions: */
        pos = this.getCursorPosition()

        /* Calculate the position of the lens: */
        x = pos.x - ((lens.offsetWidth / 2) + this.zoomOffset)//an offset allows us to see the full image even when screen size is tight
        y = pos.y - ((lens.offsetHeight / 2) + this.zoomOffset)

        this.referencePoint = [x, y]
    }

    centerResultOnCursor() {
        const lensWidthOffset = this.lens.offsetWidth / 2
        const lensHeightOffset = this.lens.offsetHeight / 2
        this.result.style.left = (this.referencePoint[0] + lensWidthOffset) + 'px'
        this.result.style.top = (this.referencePoint[1] + lensHeightOffset) + 'px'
    }

    repositionZoomBackground() {
        const positionX = (this.referencePoint[0] * this.zoom) * -1
        const positionY = (this.referencePoint[1] * this.zoom) * -1
        this.result.style.backgroundPosition = `${positionX}px ${positionY}px`
    }

    getCursorPosition(e) {
        let a, x = 0, y = 0
        e = e || window.event
        /* Get the x and y positions of the image: */
        a = this.image.getBoundingClientRect()
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e ? e.pageX - a.left : 0
        y = e ? e.pageY - a.top : 0
        /* Consider any page scrolling: */
        x = x - window.pageXOffset
        y = y - window.pageYOffset
        return { x: x, y: y }
    }

    setZoomContainerSize(image) {
        //calculate the current width and height of the image, based on the original specs & aspect ratio
        if (image === this.image) {
            image.onload = () => {
                const aspectRatio = image.naturalHeight / image.naturalWidth
                const currentHeight = image.height
                const currentWidth = currentHeight / aspectRatio

                //set the zoom container specs to match that of the image specs
                this.container.style.width = `${currentWidth}px`
                this.container.style.height = `${currentHeight}px`
            }
        }
    }
}
