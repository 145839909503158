import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const CLASSES = {
    COMPONENT: '.js-mousemove-background-shift'
}

export default class MousemoveBackgroundShift {
    constructor(element) {
        this.element = element

        this.acceleration = 0.1

        this.slidingElement = {
            element: this.element,
            xMax: 0,
            yMax: 0,
            x: 0,
            y: 0
        }

        this.mouse = { x: 0, y: 0 }
        this.vw = 0
        this.vh = 0

        this.moveActionRef = this.moveAction.bind(this)
        this.resizeRef = this.resize.bind(this)

        this.initListeners()
    }

    initListeners() {
        window.addEventListener('zoom-finished', () => {
            this.initSlidingBackground()
        })

        window.addEventListener('zoom-reversed', () => {
            window.removeEventListener('mousemove', this.moveActionRef)
            window.removeEventListener('touchmove', this.moveActionRef)
            window.removeEventListener('resize', this.resizeRef)

            this.slidingElement = {
                element: this.element,
                xMax: 0,
                yMax: 0,
                x: 0,
                y: 0
            }
        })

    }

    map(x, a, b, c, d) {
        const result = c + (d - c) * ((x - a) / (b - a)) || 0
        return result
    }

    resize() {
        this.vw = window.innerWidth
        this.vh = window.innerHeight

        this.slidingElement.xMax = this.vw - this.slidingElement.element.offsetWidth
        this.slidingElement.yMax = this.vh - this.slidingElement.element.offsetHeight
    }

    moveAction(event) {
        if (event.targetTouches && event.targetTouches[0]) {
            event.preventDefault()
            this.mouse.x = event.targetTouches[0].clientX
            this.mouse.y = event.targetTouches[0].clientY
        } else {
            this.mouse.x = event.clientX
            this.mouse.y = event.clientY
        }
    }

    initSlidingBackground() {
        // Sets viewport dimensions and slidingElement x/y limits
        this.resize()

        gsap.set(this.slidingElement.element, { x: 0, y: 0 })

        var posX = gsap.getProperty(this.slidingElement, 'x')
        var posY = gsap.getProperty(this.slidingElement, 'y')

        gsap.to(this.slidingElement.element, {
            duration: 1000,
            x: 0,
            y: 0,
            repeat: -1,
            ease: 'none',
            modifiers: {
                x: () => {
                    this.slidingElement.x = this.map(this.mouse.x, 0, this.vw, 0, this.slidingElement.xMax)
                    return posX + (this.slidingElement.x - posX) * this.acceleration + 'px'
                },
                y: () => {
                    this.slidingElement.y = this.map(this.mouse.y, 0, this.vh, 0, this.slidingElement.yMax)
                    return posY + (this.slidingElement.y - posY) * this.acceleration + 'px'
                }
            }
        })
        window.addEventListener('mousemove', this.moveActionRef)
        window.addEventListener('touchmove', this.moveActionRef)
        window.addEventListener('resize', this.resizeRef)
    }
}

export const MousemoveBackgroundShiftComponent = {
    'name': 'MousemoveBackgroundShift',
    'class': CLASSES.COMPONENT,
    'Source': MousemoveBackgroundShift
}
