import { AccordionComponent } from 'components/Accordion'
import { ModalComponent } from 'components/Modal'
import { CarouselGeneralComponent } from 'components/CarouselGeneral'
import { ImageHoverWithOverlayComponent } from 'components/ImageHoverWithOverlay'
import { MousemoveBackgroundShiftComponent } from 'components/MousemoveBackgroundShift'
import { NavComponent } from 'components/Nav'
import { PostCardComponent } from 'components/PostCard'
import { ScrollTargetComponent } from 'utils/ScrollTarget'
import { VideoComponent } from 'components/Video'
import { CustomCursorComponent } from 'utils/CustomCursor'
import { TableTopComponent } from 'components/TableTop'
import { MasonryComponent } from 'components/Masonry'
import { SequenceComponent } from 'components/Sequence'
import { SeeMoreComponent } from 'components/SeeMore'
import { EasterEggComponent } from 'components/EasterEgg'
import { GreenhouseiFrameComponent } from 'components/GreenhouseiFrame'
import { FeaturedContentFullBleedComponent } from 'components/FeaturedContentFullBleed'
import { ImageDrawerComponent } from 'components/ImageDrawer'
import { ImageFlipComponent } from './ImageFlip'
import { CookieNoticeComponent } from 'components/CookieNotice'
import { NewsletterSignupComponent } from 'components/NewsletterSignup'



/**
 * Add module objects alphabetically as needed
 * {
 *  name: Class Name
 *  class: html .class
 *  Source: imported module
 *  render: Function (if React SSR component)
 * }
 */

export const modules = [
    AccordionComponent,
    ModalComponent,
    CarouselGeneralComponent,
    ImageHoverWithOverlayComponent,
    MousemoveBackgroundShiftComponent,
    NavComponent,
    PostCardComponent,
    VideoComponent,
    ScrollTargetComponent,
    CustomCursorComponent,
    TableTopComponent,
    MasonryComponent,
    SequenceComponent,
    SeeMoreComponent,
    EasterEggComponent,
    GreenhouseiFrameComponent,
    FeaturedContentFullBleedComponent,
    ImageDrawerComponent,
    ImageFlipComponent,
    CookieNoticeComponent,
    NewsletterSignupComponent
]

export default modules
