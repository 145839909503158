import * as Rollbar from 'rollbar'

const rollbarConfig = {
    accessToken: ROLLBAR_CLIENT_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    ignoredMessages: ['Script error.']
}

const initRollbar = (config = rollbarConfig) => {
    return Rollbar.init(config)
}

export default initRollbar
