import { GLOBAL_CONSTANTS, isTouchDevice } from 'utils/constants'

const SELECTORS = {
    COMPONENT: '.js-img-flip-image',
    CONTAINER: '.js-img-flip-image__container'
}

export default class ImageFlip {
    constructor(element) {
        this.element = element
        this.images = Array.from(this.element.querySelectorAll(SELECTORS.CONTAINER))

        this.init()
    }

    /**
     * @desc Init.
     */
    init() {
        this.registerEvents()
    }

    /**
     * @desc Register resize listener with attached throttle
     */
    registerEvents() {
        this.images.map(image => {
            if ( isTouchDevice() ) {
                image.addEventListener('touchstart', () => {
                    if (image.classList.contains('animate-image-flip')) {
                        this.removeFlipImageAnim(image)
                    } else {
                        this.addFlipImageAnim(image)
                    }
                })
            } else {
                image.addEventListener('mouseenter', () => { this.addFlipImageAnim(image) })
                image.addEventListener('mouseleave', () => { this.removeFlipImageAnim(image) })
            }
        })
    }

    addFlipImageAnim(image) {
        image.classList.add('animate-image-flip')
    }

    removeFlipImageAnim(image) {
        setTimeout(() => {
            image.classList.remove('animate-image-flip')
        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
    }
}


/**
 * @desc Test component definition used in module-loader
 */

export const ImageFlipComponent = {
    'name': 'ImageFlip',
    'class': SELECTORS.COMPONENT,
    'Source': ImageFlip
}