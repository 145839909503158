import Emitter from 'utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS, isStandardTablet } from 'utils/constants'
import { preventScroll, allowScroll } from 'utils/scrollUtil'

const RESIZE_DISTANCE = {
    TABLET: 275,
    MOBILE: 88
}


/**
 * Makes the nav show / hide based on
 * scroll position / direction.
 */

const CLASSES = {
    COMPONENT: '.js-navigation',
    NAV_LINK: '.js-nav-menu-link',
    NAV_MENU: '.js-nav-menu',
    NAV_OVERLAY: '.js-nav-overlay',
    NAV_ACTIVE: 'nav-active',
    NAV_HIDDEN: 'nav-hidden',
    BODY_NAV_HIDDEN: 'body--nav-hidden',
    MOBILE_NAV_ITEM: 'js-mobile-item',
    HEADER_ITEM: '.js-header-item',
    SEARCH: '.js-search',
    LOGO: '.js-header-logo'
}

export default class Nav {
    /**
     * @desc Set up nav with elements and bind events.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */

    constructor(element) {
        this.el = element
        this.throttleScroll = null
        this.scrollPosition = 0
        this.navLink = this.el.querySelector(CLASSES.NAV_LINK)
        this.navMenu = this.el.querySelector(CLASSES.NAV_MENU)
        this.navMenuWrapper = this.navMenu.querySelector('.navigation__menu')
        this.navOverlay = this.el.querySelector(CLASSES.NAV_OVERLAY)
        this.navItems = Array.from(this.navMenu.getElementsByTagName('A'))
        this.headerItems = Array.from(this.el.querySelectorAll(CLASSES.HEADER_ITEM))
        this.search = this.el.querySelector(CLASSES.SEARCH)
        this.logo = this.el.querySelector(CLASSES.LOGO)

        this.handleMenuKeyDown = this.handleMenuKeyDown.bind(this)
        this.handleMobileMenuClick = this.handleMobileMenuClick.bind(this)

        this.handleScroll()
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }


    /**
     * @desc Listen for the global scroll event.
     */
    registerEvents () {
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)

        this.navLink.addEventListener('click', this.handleMobileMenuClick)
        this.navOverlay.addEventListener('click', this.handleMobileMenuClick.bind(this))

        this.navItems.forEach(item => {
            const bgColor = item.getAttribute('data-bg-color')
            item.addEventListener('mouseover', () => this.changeBackgroundColor(bgColor))
        })

        Emitter.on(GLOBAL_CONSTANTS.EVENTS.HIDE_SHY_NAV, this.hideShyNav.bind(this))
    }

    hideShyNav() {
        if (!this.el.classList.contains(CLASSES.NAV_HIDDEN)) {
            this.el.classList.add(CLASSES.NAV_HIDDEN)
        }

        if (!document.body.classList.contains(CLASSES.BODY_NAV_HIDDEN)) {
            document.body.classList.add(CLASSES.BODY_NAV_HIDDEN)
        }
    }


    /**
     * @desc Handle key down for menu to set proper focus
     */
    handleMenuKeyDown (e) {
        let visible = this.navItems
        if ( !isStandardTablet() ) {
            visible = this.navItems.filter(item => {
                return !item.parentNode.classList.contains(CLASSES.MOBILE_NAV_ITEM)
            })
        }

        const keyCode = e.keyCode || e.which
        if (document.body.classList.contains(CLASSES.NAV_ACTIVE) && keyCode === 27) { // Handles escape key
            this.handleMobileMenuClosed()
            this.navLink.focus()
        } else if (e.target === visible[visible.length - 1] && !e.shiftKey && keyCode === 9) { // Handles tabbing past the last menu item to return to menu toggle button
            if ( !isStandardTablet() ) {
                this.search.focus()
            } else {
                this.logo.focus()
            }
        }
    }

    /**
     * @desc Handles orientation change by firing our handleScroll function
     */
    handleOrientationChange () {
        this.handleScroll(true)
    }

    /**
     * @desc Scroll event that determines if the nav should show or hide.
     */
    handleScroll (hasOrientationChanged) {
        if (hasOrientationChanged) {
            this.setActive(false)
            return
        }

        const pos = Math.max(0, window.pageYOffset)
        const delta = this.scrollPosition - pos
        const isHomepage = document.body.classList.contains('template-homepage')

        const minimizedThreshold = (() => {
            if (isHomepage) {
                return !isStandardTablet()
                    ? RESIZE_DISTANCE.TABLET
                    : RESIZE_DISTANCE.MOBILE
            } else {
                return 60
            }
        })()
        const activeThreshold = (() => {
            if (isHomepage) {
                return !isStandardTablet()
                    ? RESIZE_DISTANCE.TABLET
                    : RESIZE_DISTANCE.MOBILE
            } else {
                return 15
            }
        })()
        const showThreshold = 14
        const hideThreshold = -5

        // Handles showing and hiding the nav
        if (pos < minimizedThreshold) {
            this.el.classList.remove(CLASSES.NAV_HIDDEN)
            document.body.classList.remove(CLASSES.BODY_NAV_HIDDEN)
        } else if (delta >= showThreshold) {
            this.el.classList.remove(CLASSES.NAV_HIDDEN)
            document.body.classList.remove(CLASSES.BODY_NAV_HIDDEN)
        } else if (delta <= hideThreshold) {
            this.el.classList.add(CLASSES.NAV_HIDDEN)
            document.body.classList.add(CLASSES.BODY_NAV_HIDDEN)
        }

        if (pos < activeThreshold && !this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.FIXED)) {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }

        this.scrollPosition = pos
    }

    handleMobileMenuClick (e) {
        e.preventDefault()
        if (document.body.classList.contains(CLASSES.NAV_ACTIVE)) {
            this.handleMobileMenuClosed()
            document.body.removeEventListener('keydown', this.handleMenuKeyDown)
            Emitter.emit(GLOBAL_CONSTANTS.EVENTS.MOBILE_NAV_OPEN, false)
        } else {
            document.body.addEventListener('keydown', this.handleMenuKeyDown)

            this.navLink.setAttribute('aria-expanded', true)
            this.navLink.setAttribute('aria-label', 'Close Menu')
            this.navMenu.setAttribute('aria-hidden', false)

            document.body.classList.add(CLASSES.NAV_ACTIVE)
            Emitter.emit(GLOBAL_CONSTANTS.EVENTS.MOBILE_NAV_OPEN, true)
            this.scrollPosition = window.pageYOffset
            document.body.classList.contains(CLASSES.NAV_ACTIVE) && preventScroll()

            this.navItems.forEach(item => {
                item.setAttribute('tabindex', '0')
            })
        }
    }

    handleMobileMenuClosed () {
        document.body.classList.remove(CLASSES.NAV_ACTIVE)
        this.navLink.setAttribute('aria-expanded', false)
        this.navLink.setAttribute('aria-label', 'Open Menu')
        this.navMenu.setAttribute('aria-hidden', true)

        this.navItems.forEach(item => {
            item.setAttribute('tabindex', '-1')
        })
        allowScroll()
    }

    changeBackgroundColor (color) {
        this.navMenuWrapper.classList.add('-bg-color-' + color)

        for (let i = this.navMenuWrapper.classList.length - 1; i >= 0; i--) {
            const className = this.navMenuWrapper.classList[i]
            if (className.startsWith('-bg-color-') && className !== '-bg-color-' + color) {
                this.navMenuWrapper.classList.remove(className)
            }
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown () {
        Emitter.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NavComponent = {
    'name': 'Nav',
    'class': CLASSES.COMPONENT,
    'Source': Nav
}
