import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GLOBAL_CONSTANTS, isTablet } from 'utils/constants'

gsap.registerPlugin(ScrollTrigger)

const CLASSES = {
    COMPONENT: '.js-image-hover-with-overlay',
    ARTIFACT: '.js-image-hover-overlay-artifact',
    OVERLAY: '.js-image-hover-overlay',
    OVERLAY_EYEBROW: '.js-image-hover-overlay-eyebrow',
    OVERLAY_HEADING: '.js-image-hover-overlay-heading'
}

export default class ImageHoverWithOverlay {
    constructor(element) {
        this.element = element
        this.artifacts = Array.from(this.element.querySelectorAll(CLASSES.ARTIFACT))
        this.overlay = this.element.querySelector(CLASSES.OVERLAY)
        this.overlayEyebrow = this.element.querySelector(CLASSES.OVERLAY_EYEBROW)
        this.overlayHeading = this.element.querySelector(CLASSES.OVERLAY_HEADING)

        // if device is larger than 1024 init hover
        // else add active class so artifact are clickable on tablets
        if (!isTablet()) {
            this.init()
        } else {
            this.artifacts.forEach((artifact) => {
                artifact.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            })
        }
    }

    init() {
        window.addEventListener('zoom-finished', () => {
            this.initImageHover()
        })

        window.addEventListener('zoom-reversed', () => {
            this.artifacts.forEach((artifact) => {
                artifact.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            })
        })

    }

    initImageHover() {
        this.artifacts.forEach((artifact) => {
            artifact.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            artifact.addEventListener('mouseenter', () => {
                this.overlayEyebrow.innerText = artifact.dataset.exhibitNumber
                this.overlayHeading.innerText = artifact.dataset.exhibitTitle
                this.overlay.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

                this.artifacts.forEach((hoverArtifact) => {
                    if (
                        artifact.dataset.exhibitTitle !== hoverArtifact.dataset.exhibitTitle
                    ) {
                        hoverArtifact.style.opacity = 0.1
                    }
                })
            })

            artifact.addEventListener('mouseout', () => {
                this.overlay.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                this.artifacts.forEach((hoverArtifact) => {
                    hoverArtifact.style.opacity = 1
                })
            })
        })
    }
}

export const ImageHoverWithOverlayComponent = {
    'name': 'ImageHoverWithOverlay',
    'class': CLASSES.COMPONENT,
    'Source': ImageHoverWithOverlay
}