import { gsap } from 'gsap'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import { throttle } from 'throttle-debounce'
import Emitter from 'utils/emitter'

import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GLOBAL_CONSTANTS } from 'utils/constants'

gsap.registerPlugin(ScrollTrigger)

const CLASSES = {
    COMPONENT: '.js-masonry-grid-wrapper-mobile',
    GRID: '.js-masonry-grid',
    MOBILE_SCROLL_TRIGGER: '.js-mobile-scroll-trigger',
    TOP_GRID: '.js-masonry-top-grid',
    BOTTOM_GRID: '.js-masonry-bottom-grid',
    GRID_ITEM: '.js-grid-item',
    GRID_HEADING: '.js-masonry-heading',
    GRID_HEADING_CONTAINER: '.js-grid-heading-container',
    GRID_SUBHEADING: '.js-masonry-subheading',
    TOP_GRID_ITEM: '.js-top-grid-item',
    BOTTOM_GRID_ITEM: '.js-bottom-grid-item'
}

export default class MasonryMobile {
    constructor() {
        this.element = document.querySelector(CLASSES.COMPONENT)
        this.grid = this.element.querySelector(CLASSES.GRID)
        this.topGrid = this.element.querySelector(CLASSES.TOP_GRID)
        this.bottomGrid = this.element.querySelector(CLASSES.BOTTOM_GRID)
        this.gridItems = this.element.querySelectorAll(CLASSES.GRID_ITEM)
        this.gridHeading = this.element.querySelector(CLASSES.GRID_HEADING)
        this.topMsnry
        this.bottomMsnry
        this.trickleAnimationSpeed = 20
        this.initialGridHeight = this.grid.getBoundingClientRect().height

        this.registerEvents()
        this.initMasonry()
    }

    registerEvents() {
        this.throttleResize = throttle(GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE, this.resize.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.throttleResize)
    }

    resize() {
        if (document.body.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            this.gridItems.forEach(item => item.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOW))
        }
        this.topMsnry.layout()
        this.bottomMsnry.layout()
        this.getInitialContainerHeight()
    }

    initMasonry() {
        this.topMsnry = new Masonry( this.topGrid, {
            itemSelector: CLASSES.TOP_GRID_ITEM,
            columnWidth: 75,
            gutter: 20,
            fitWidth: true,
            originTop: false,
            transitionDuration: 0,
            resize: false
        })

        this.bottomMsnry = new Masonry( this.bottomGrid, {
            itemSelector: CLASSES.BOTTOM_GRID_ITEM,
            columnWidth: 75,
            gutter: 20,
            fitWidth: true,
            transitionDuration: 0,
            resize: false
        })
        this.handleImagesLoaded()
    }

    handleImagesLoaded() {
        const gridStatusHash = {top: false, bottom: false}

        imagesLoaded( this.topGrid ).on( 'progress', () => {
            // Triggered after each image has been loaded
            this.topMsnry.layout()
        })

        imagesLoaded( this.bottomGrid ).on( 'progress', () => {
            // Triggered after each image has been loaded
            this.bottomMsnry.layout()
        })

        imagesLoaded( this.bottomGrid ).on( 'always', () => {
            // Triggered after all images have been either loaded or confirmed broken.
            gridStatusHash.bottom = true

            if (gridStatusHash.top) {
                this.trickleAnimation()
                this.getInitialContainerHeight()

                setTimeout(() => { // settimeout prevents a layout bug
                    this.initScrollAnimations()
                }, 0)
            }
        })

        imagesLoaded( this.topGrid ).on( 'always', () => {
            // Triggered after all images have been either loaded or confirmed broken.
            gridStatusHash.top = true

            if (gridStatusHash.bottom) {
                this.trickleAnimation()
                this.getInitialContainerHeight()

                setTimeout(() => { // settimeout prevents a layout bug
                    this.initScrollAnimations()
                }, 0)

            }
        })
    }

    initScrollHeadingAnimation() {
        let tl = gsap.timeline({
            scrollTrigger: {
                // markers: true,
                trigger: CLASSES.MOBILE_SCROLL_TRIGGER,
                pin: true, // pin the trigger element while active
                start: 'top top', // when the top of the trigger hits the top of the viewport
                end: '+=500', // end after scrolling 500px beyond the start
                scrub: 1 // smooth scrubbing, takes 1 second to 'catch up' to the scrollbar
            }
        })

        tl
            .from(CLASSES.GRID_HEADING, {opacity: 1, duration: 0.8})
            .to(CLASSES.GRID_HEADING, {opacity: 0, duration: 0.8})
            .to(CLASSES.GRID_SUBHEADING, {opacity: 1, duration: 2})
            .to(CLASSES.GRID_SUBHEADING, {opacity: 0, duration: 2})
            .to(CLASSES.GRID_HEADING_CONTAINER, {opacity: 0, duration: 1})
    }

    initScrollAnimations() {
        this.initScrollHeadingAnimation()
    }

    adjustContainerMargin() {
        const { height } = this.grid.getBoundingClientRect()
        const difference = (height - this.initialGridHeight) / 2
        this.element.style.paddingBottom = difference + 'px'
    }

    getInitialContainerHeight() {
        const { height } = this.grid.getBoundingClientRect()
        this.initialGridHeight = height
    }

    trickleAnimation() {
        setTimeout(() => { //prevents header from flashing before grid is ready
            this.gridHeading.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOW)
        }, 0)

        const windowCenterY = window.innerHeight / 2
        const windowCenterX = window.innerWidth / 2

        const calculateDistance = (x, y) => {
            const xSq = x * x
            const ySq = y * y

            return Math.sqrt(xSq + ySq)
        }

        const itemMap = Array.from(this.gridItems)
            .filter(item => {
                const { top } = item.getBoundingClientRect()
                if (top > window.innerHeight) {
                    item.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOW)
                } else {
                    return top < window.innerHeight
                }
            })
            .map(item => {
                const {top, left, width, height} = item.getBoundingClientRect()
                const itemCenterY = top + (.5 * height)
                const itemCenterX = left + (.5 * width)
                const deltaX = windowCenterX - itemCenterX
                const deltaY = windowCenterY - itemCenterY
                const distance = Math.abs(calculateDistance(deltaX, deltaY))
                return { distance, element: item }
            })

        itemMap.sort((a, b) => {
            return a.distance < b.distance ? -1 : 1
        })

        itemMap.forEach((item, index) => {
            setTimeout(() => {
                item.element.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOW)
            }, index * this.trickleAnimationSpeed)
        })

        setTimeout(() => {
            document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }, itemMap.length * this.trickleAnimationSpeed)

    }
}

export const MasonryMobileComponent = {
    'name': 'MasonryMobile',
    'class': CLASSES.COMPONENT,
    'Source': MasonryMobile
}
