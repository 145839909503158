import Swiper, { Mousewheel } from 'swiper'

const CLASSES = {
    COMPONENT: '.sequence-block',
    WRAPPER: '.sequence-block__wrapper',
    SLIDE: '.sequence-block__slide',
    IMAGE: '.sequence-block__image',
    DESCRIPTION: '.sequence-block__description'
}

export default class Sequence {
    constructor(element) {
        this.element = element
        this.slides = this.element.querySelectorAll(CLASSES.SLIDE)
        this.initialize()
    }

    updateHeights = () => {
        const imageHeight = this.element.querySelector(CLASSES.IMAGE).offsetHeight
        this.element.style.setProperty('--image-height', `${imageHeight}px`)

        this.slides.forEach(slide => {
            const descriptionHeight = slide.querySelector(CLASSES.DESCRIPTION).offsetHeight
            slide.style.setProperty('--description-height', `${descriptionHeight}px`)
        })
    }

    initialize = () => {
        this.swiper = new Swiper(this.element, {
            breakpoints: {
                0: { slidesPerView: 1.25 },
                768: { slidesPerView: 2.25 },
                1160: { slidesPerView: 3.25 },
                1920: { slidesPerView: 4.25 }
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true
            },
            modules: [Mousewheel],
            mousewheel: { forceToAxis: true },
            on: {
                resize: () => {
                    clearTimeout(this.resizeTimer)
                    this.resizeTimer = setTimeout(() => this.updateHeights(), 500)
                }
            },
            slideClass: CLASSES.SLIDE.substring(1),
            wrapperClass: CLASSES.WRAPPER.substring(1)
        })
    }
}

export const SequenceComponent = {
    'name': 'Sequence',
    'class': CLASSES.COMPONENT,
    'Source': Sequence
}
