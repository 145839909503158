import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Image Drawer
 */
const SELECTORS = {
    COMPONENT: '.js-image-drawer',
    WRAPPER: '.js-image-drawer__wrapper'
}
export default class ImageDrawer {

    constructor(element) {
        // This can be made dynamic when needed currently no use case
        this.element = element
        this.wrapper = this.element.querySelector(SELECTORS.WRAPPER)
        this.initialize()
    }
    /**
     * @desc Init.
     */
    initialize() {
        this.registerEvents()
    }

    /**
     * @desc Listen for the global scroll event.
     */
    registerEvents() {
        this.resetImageDrawerHeight.bind(this)
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.setImageDrawerHeight.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.GET_IMAGE_DRAWER_HEIGHT, this.setImageDrawerHeight.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESET_IMAGE_DRAWER_HEIGHT, this.resetImageDrawerHeight.bind(this))
    }

    setImageDrawerHeight() {
        this.element.style.maxHeight = this.wrapper.clientHeight + 'px'
    }

    resetImageDrawerHeight() {
        this.element.style.maxHeight = 0
    }
}

/**
 * @desc ImageDrawerComponent definition used in module-loader
 */
export const ImageDrawerComponent = {
    'name': 'ImageDrawer',
    'class': SELECTORS.COMPONENT,
    'Source': ImageDrawer
}
