/**
 * Adapted from https://github.com/ghosh/Micromodal/blob/master/src/index.js
 * Verion: 0.4.0
 */
import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'
import { preventScroll, allowScroll } from 'utils/scrollUtil'


const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])'
]

class MicroModal {
    constructor ({
        targetModal,
        triggers = [],
        onShow = () => {},
        onClose = () => {},
        openTrigger = 'data-micromodal-trigger',
        closeTrigger = 'data-micromodal-close',
        disableFocus = false,
        awaitCloseAnimation = false,
        debugMode = false,
        startOpen = false
    }) {
        this.version = 0.4
        // Save a reference of the modal
        this.modal = document.getElementById(targetModal)
        this.validateModalPresence(this.modal, targetModal)

        // Save a reference to the passed config
        this.config = { debugMode, openTrigger, closeTrigger, onShow, onClose, awaitCloseAnimation, disableFocus }

        // Register click events only if prebinding eventListeners
        if (triggers.length > 0) {
            this.registerTriggers(...triggers)
        } else {
            this.validateTriggerPresence()
        }

        // prebind functions for event listeners
        this.onClick = this.onClick.bind(this)
        this.onKeydown = this.onKeydown.bind(this)

        if (startOpen) {
            this.showModal()
        }
    }

    /**
     * Loops through all openTriggers and binds click event
     * @param  {array} triggers [Array of node elements]
     * @return {void}
     */
    registerTriggers (...triggers) {
        triggers.filter(Boolean).forEach(trigger => {
            trigger.addEventListener('click', () => this.showModal())
        })
    }

    validateTriggerPresence () {
        console.warn(`MicroModal v${this.version}: \u2757Please specify at least one %c'micromodal-trigger'`, 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', 'data attribute.')
        console.warn('%cExample:', 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', '<a href="#" data-micromodal-trigger="my-modal"></a>')
    }

    validateModalPresence (modal, id) {
        if (!modal) {
            console.warn(`MicroModal v${this.version}: \u2757Seems like you have missed %c'${id}'`, 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', 'ID somewhere in your code. Refer example below to resolve it.')
            console.warn('%cExample:', 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', `<div class="modal" id='${id}'></div>`)
        }
    }

    showModal () {
        this.activeElement = document.activeElement
        this.modal.setAttribute('aria-hidden', 'false')
        this.modal.classList.add('is-open')
        this.setFocusToFirstNode()
        Emitter.emit(GLOBAL_CONSTANTS.EVENTS.MODAL_OPEN, true)
        preventScroll()
        this.addEventListeners()
        this.config.onShow(this.modal)
    }

    closeModal () {
        const modal = this.modal
        this.modal.setAttribute('aria-hidden', 'true')
        this.removeEventListeners()
        Emitter.emit(GLOBAL_CONSTANTS.EVENTS.MODAL_OPEN, false)
        allowScroll()
        if (this.activeElement) {
            this.activeElement.focus({ preventScroll: true })
        }

        const that = this

        if (this.config.awaitCloseAnimation) {
            this.modal.addEventListener('animationend', function handler () {
                modal.classList.remove('is-open')
                modal.removeEventListener('animationend', handler, false)
                that.config.onClose(this.modal)

            }, false)
        } else {
            modal.classList.remove('is-open')
            that.config.onClose(this.modal)
        }
    }

    closeModalById (targetModal) {
        this.modal = document.getElementById(targetModal)
        if (this.modal) {this.closeModal()}
    }

    addEventListeners () {
        this.modal.addEventListener('touchstart', this.onClick)
        this.modal.addEventListener('click', this.onClick)
        document.addEventListener('keydown', this.onKeydown)
    }

    removeEventListeners () {
        this.modal.removeEventListener('touchstart', this.onClick)
        this.modal.removeEventListener('click', this.onClick)
        document.removeEventListener('keydown', this.onKeydown)
    }

    onClick (event) {
        if (event.target.hasAttribute(this.config.closeTrigger)) {
            this.closeModal()
            event.preventDefault()
        }
    }

    onKeydown (event) {
        if (event.key === 'Escape') {this.closeModal(event)}
        if (event.key === 'Tab') {this.maintainFocus(event)}
    }

    getFocusableNodes () {
        const nodes = this.modal.querySelectorAll(FOCUSABLE_ELEMENTS)
        return Array(...nodes)
    }

    setFocusToFirstNode () {
        if (this.config.disableFocus) {return}
        const focusableNodes = this.getFocusableNodes()
        if (focusableNodes.length) {focusableNodes[0].focus()}
    }

    maintainFocus (event) {
        const focusableNodes = this.getFocusableNodes()

        // if disableFocus is true
        if (!this.modal.contains(document.activeElement)) {
            focusableNodes[0].focus()
        } else {
            const focusedItemIndex = focusableNodes.indexOf(document.activeElement)

            if (event.shiftKey && focusedItemIndex === 0) {
                focusableNodes[focusableNodes.length - 1].focus()
                event.preventDefault()
            }

            if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
                focusableNodes[0].focus()
                event.preventDefault()
            }
        }
    }
}


export default MicroModal
