import Cookies from 'js-cookie'

const SELECTORS = {
    COMPONENT: '#cookie-consent-notice',
    BUTTON: '#cookie-consent-notice__button'
}

export default class CookieNotice {
    /**
     * @desc Show a cookie notice to users that have not already
     * opted-in to tracking consent.
     */

    constructor() {
        this.initialize()
    }

    initialize() {
        const cookieName = 'tracking_consent'
        const consent = Cookies.get(cookieName)
        const notice = document.querySelector(SELECTORS.COMPONENT)

        if ( consent ) {
            notice.remove()
            return
        }

        const button = document.querySelector(SELECTORS.BUTTON)

        button.addEventListener('click', () => {
            Cookies.set(cookieName, '1', { expires: 365 })
            notice.classList.add('cookie-notice--slide')
            notice.ontransitionend = (event) => {
                if (event.propertyName === 'transform') { notice.remove() }
            }
        })
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const CookieNoticeComponent = {
    'name': 'CookieNotice',
    'class': SELECTORS.COMPONENT,
    'Source': CookieNotice
}
