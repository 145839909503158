import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS, isStandardTablet } from 'utils/constants'
import { throttle } from 'throttle-debounce'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Meet Your Host / Table Top
 * scroll position / direction.
 */

const SELECTORS = {
    COMPONENT: '.js-table-top',
    BLOCKS: '.js-meet-your-host__block',
    TABLETOPBG: '.js-table-top-bg',
    INTRO_BLOCK: 'js-meet-your-host__intro',
    VIDEO_BLOCK: 'js-meet-your-host__video',
    FULL_LAYOUT_BLOCK: '.js-table-top__container--full',
    VIDEO: '.js-meet-your-host__video__iframe .video__player-wrapper',
    VIDEO_CLOSE: '.js-meet-your-host__video__iframe-close',
    VIDEO_HTML5: '.js-html5video-video',
    VIDEO_YOUTUBE: '.js-youtube-video',
    COL_MD_8: 'col-md-8',
    COL_MD_16: 'col-md-16'
}

export default class TableTop {
    /**
     * @desc Set up nav with elements and bind events.
     * @param {HTMLElement} element - Element that contains possible sub-navigations
     *
     */

    constructor(element) {
        this.element = element
        this.blocks = this.element.querySelectorAll(SELECTORS.BLOCKS)
        this.tableTopBlocks = this.element.querySelectorAll(SELECTORS.TABLETOPBLOCKS)
        this.tableTopBg = this.element.querySelectorAll(SELECTORS.TABLETOPBG)
        this.videoBlock = this.element.querySelector(`.${SELECTORS.VIDEO_BLOCK}`)
        this.introBlock = this.element.querySelector(`.${SELECTORS.INTRO_BLOCK}`)
        this.video = this.element.querySelector(SELECTORS.VIDEO)
        this.videoHtml5 = this.video.querySelector(SELECTORS.VIDEO_HTML5)
        this.videoYoutube = this.video.querySelector(SELECTORS.VIDEO_YOUTUBE)

        this.scrollEndPosition = null
        this.throttleResize = null
        this.sections = null
        this.tl = null

        this.videoClose = this.element.querySelector(SELECTORS.VIDEO_CLOSE)
        this.videoBlockScroll = this.videoBlock.clientWidth / window.innerWidth

        this.initialize()
    }

    /**
     * @desc Init.
     */
    initialize() {
        this.registerEvents()
        if (this.tableTopBg) {
            this.handleResize()
        }
    }

    /**
     * @desc Register resize listener with attached throttle
     */
    registerEvents() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SHOW_TABLE_TOP, () => {
            this.createController()
            Emitter.off(GLOBAL_CONSTANTS.EVENTS.SHOW_TABLE_TOP)
        })

        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.setTableTopHeight.bind(this))

        if (this.tableTopBg) {
            this.throttleResize = throttle(GLOBAL_CONSTANTS.TIMING.NAV_RESIZE_THROTTLE, this.handleResize.bind(this))
            Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.throttleResize)

            Array.from(this.tableTopBg).map(item => {
                item.addEventListener('mouseenter', (event) => {
                    const bgColor = event.target.getAttribute('data-bg-color')
                    event.target.classList.add('-bg-color-' + bgColor)
                })

                item.addEventListener('mouseleave', (event) => {
                    for (let i = event.target.classList.length - 1; i >= 0; i--) {
                        const className = event.target.classList[i]
                        if (className.startsWith('-bg-color-')) {
                            event.target.classList.remove(className)
                        }
                    }
                })
            })
        }

        this.closeVideo = () => {
            this.video.classList.remove(GLOBAL_CONSTANTS.CLASSES.PINNED)
            this.video.classList.add(GLOBAL_CONSTANTS.CLASSES.IS_CLOSED)
            if (this.videoHtml5) {
                this.videoHtml5.pause()
            } else if (this.videoYoutube) {
                this.videoYoutube.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
            }
        }
    }

    /**
     * @desc Show color-fill background on mobile and get videoScrollBlock value for ScrollTrigger.
     */
    handleResize() {
        // Change background color
        Array.from(this.tableTopBg).map(item => {
            if ( isStandardTablet() ) {
                const bgColor = item.getAttribute('data-bg-color')
                item.classList.add('-bg-color-' + bgColor)
            } else {
                for (let i = item.classList.length - 1; i >= 0; i--) {
                    const className = item.classList[i]
                    if (className.startsWith('-bg-color-')) {
                        item.classList.remove(className)
                    }
                }
            }
        })

        this.scrollEndPosition = this.element.scrollWidth - window.innerWidth
        this.length = this.scrollEndPosition / window.innerWidth
        this.videoBlockScroll = this.videoBlock.clientWidth / window.innerWidth
    }

    /**
     * @desc Create ScrollTrigger controller
     */
    createController() {
        this.sections = gsap.utils.toArray(this.blocks)
        this.setTableTopHeight()

        ScrollTrigger.matchMedia({
            // Tablet value is from GLOBAL_CONSTANTS.BREAKPOINTS.TABLET
            // Height value is from GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP_HEIGHT_SM
            '(min-width: 768px) and (min-height: 801px)': () => {
                this.scrollEndPosition = this.element.scrollWidth - window.innerWidth
                this.length = this.scrollEndPosition / window.innerWidth

                this.tl = gsap.to(this.element, {
                    xPercent: () => -100 * (this.length),
                    ease: 'none',
                    scrollTrigger: {
                        trigger: this.element,
                        pin: true,
                        scrub: 0.5,
                        start: 'top top',
                        end: () => '+=' + 100 * (this.length) + '%',
                        fastScrollEnd: true,
                        pinSpacing: 'margin',
                        invalidateOnRefresh: true
                    }
                })

                this.videoBlockScroll = this.videoBlock.clientWidth / window.innerWidth
                if (this.element.scrollWidth > window.innerWidth) {
                    gsap.to(this.video, {
                        // This `x` calculation is the final positin of the pinned video.
                        // It's total width of the Table Top container, minus browser width and one artifact container width).
                        x: () => { return + (this.element.scrollWidth - window.innerWidth - this.videoBlock.clientWidth) },
                        ease: 'none',
                        scrollTrigger: {
                            trigger: this.videoBlock,
                            start: this.videoBlockScroll * 100 + '%',
                            endTrigger: this.element,
                            end: () => '+=' + 100 * (this.length - this.videoBlockScroll) + '%',
                            scrub: 0.5,
                            fastScrollEnd: true,
                            invalidateOnRefresh: true,
                            onEnter: () => {
                                this.video.classList.remove(GLOBAL_CONSTANTS.CLASSES.IS_CLOSED)
                                this.video.classList.add(GLOBAL_CONSTANTS.CLASSES.PINNED)
                                this.videoClose.addEventListener('click', this.closeVideo)
                            },
                            onLeaveBack: () => {
                                this.video.classList.remove(GLOBAL_CONSTANTS.CLASSES.PINNED)
                            }
                        }
                    })
                    const scaleVideo = gsap.timeline({
                        scrollTrigger: {
                            trigger: this.videoBlock,
                            start: this.videoBlockScroll * 100 + '%',
                            endTrigger: this.element,
                            toggleActions: 'play none none reverse',
                            end: () => '+=' + 100 * (this.length - this.videoBlockScroll) + '%',
                            scrub: false,
                            fastScrollEnd: true,
                            invalidateOnRefresh: true
                        }
                    })

                    scaleVideo.fromTo(this.video, {
                        y: 0,
                        scale: 1
                    }, {
                        y: `${(this.videoBlock.clientHeight - (this.video.clientHeight * 0.5)) / 2}px`,
                        scale: 0.5
                    })
                }
            },
            '(max-width: 767px)': () => {
                this.tl = gsap.to(this.element, {
                    ease: 'none',
                    scrollTrigger: {
                        horizontal: true,
                        scroller: '.js-meet-your-host__wrapper',
                        trigger: this.element,
                        pin: false,
                        scrub: 0.5,
                        snap: 1 / (GLOBAL_CONSTANTS.TABLE_TOP.MOBILE_BLOCK_LENGTH - 1),
                        start: 'top top',
                        end: () => 100 + '%',
                        pinSpacing: false,
                        invalidateOnRefresh: true
                    }
                })
            }
        })
    }

    setTableTopHeight() {
        const windowHeight = window.innerHeight
        const blocks = [...this.blocks]

        if (windowHeight <= GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP_HEIGHT_SM) {
            blocks.forEach(block => {
                const isFullLayout = block.querySelector(SELECTORS.FULL_LAYOUT_BLOCK)

                if (!block.classList.contains(SELECTORS.INTRO_BLOCK) && !block.classList.contains(SELECTORS.VIDEO_BLOCK) && !isFullLayout) {
                    block.classList.remove(SELECTORS.COL_MD_8)
                    block.classList.add(SELECTORS.COL_MD_16)
                }
            })
        } else {
            blocks.forEach(block => {
                const isFullLayout = block.querySelector(SELECTORS.FULL_LAYOUT_BLOCK)

                if (!block.classList.contains(SELECTORS.INTRO_BLOCK) && !block.classList.contains(SELECTORS.VIDEO_BLOCK) && !isFullLayout) {
                    block.classList.add(SELECTORS.COL_MD_8)
                    block.classList.remove(SELECTORS.COL_MD_16)
                }
            })
        }
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const TableTopComponent = {
    'name': 'TableTop',
    'class': SELECTORS.COMPONENT,
    'Source': TableTop
}
