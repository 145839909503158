const CLASSES = {
    COMPONENT: '.newsletter_embed_signup',
    FORM: '.newsletter_embed_signup--hs',
    BUTTON: '.newsletter-signup__button',
    CONTEXT: '.newsletter_embed_signup__hs_context',
    SUCCESS: '.hs-success-response',
    ERROR: '.hs-error-response',
    INLINE_ERROR: '.hs-inline-error',
    HONEYPOT: 'b_91739ed65e93cf70c2f55d789_29ada75a73'

}

function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
}

export default class NewsletterSignup {
    /**
     * @desc Set up see more with elements and bind events.
     * @param {HTMLElement} el - Element that contains newsletter form
     *
     */

    constructor(element) {
        this.el = element
        this.form = this.el.querySelector(CLASSES.FORM)
        this.formButton = this.el.querySelector(CLASSES.BUTTON)
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        let context = {
            pageUrl: window.location.href,
            pageName: document.title,
            hutk: getCookie('hubspotutk')
        }
        this.form.querySelector(CLASSES.CONTEXT).value = JSON.stringify(context)
        this.registerEvents()
    }

    /**
     * @desc Listen for form submit click
     */
    registerEvents () {
        this.form.addEventListener('submit', this.handleSubmit.bind(this))
        this.formButton.addEventListener('click', this.handleSubmit.bind(this))
    }

    /**
     * @desc Handle form submit
     */
    handleSubmit (e) {
        e.preventDefault()

        this.form.querySelector(CLASSES.SUCCESS).classList.add('-hidden')
        this.form.querySelector(CLASSES.INLINE_ERROR).classList.add('-hidden')
        this.form.querySelector(CLASSES.ERROR).classList.add('-hidden')

        if (!this.form[CLASSES.HONEYPOT].value && this.form.checkValidity()) {
            let formParams = new URLSearchParams(new FormData(this.form))
            formParams.delete(CLASSES.HONEYPOT)

            fetch(this.form.action, {
                method: 'POST',
                body: formParams
            }).then((response) => {
                // See status codes
                // https://legacydocs.hubspot.com/docs/methods/forms/v2/submit_form
                switch (response.status) {
                case 204:
                case 302:
                    this.form.querySelector(CLASSES.SUCCESS).classList.remove('-hidden')
                    break
                default:
                    this.form.querySelector(CLASSES.ERROR).classList.remove('-hidden')
                    break
                }

                if (!response.ok) {
                    this.form.querySelector(CLASSES.ERROR).classList.remove('-hidden')
                    throw new Error(`Form Submit Error! Status: ${response.status}`)
                }
            })
        } else {
            this.form.querySelector(CLASSES.INLINE_ERROR).classList.remove('-hidden')
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown () {
        this.form.removeEventListener('submit', this.handleSubmit)
        this.formButton.removeEventListener('submit', this.handleSubmit)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NewsletterSignupComponent = {
    'name': 'NewsletterSignup',
    'class': CLASSES.COMPONENT,
    'Source': NewsletterSignup
}
