import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS, isTablet } from 'utils/constants'

const SELECTORS = {
    COMPONENT: '.js-scroll-target'
}

const CLASSES = {
    TABLETOP: 'js-table-top',
    IMAGE_DRAWER: 'js-image-drawer'
}

export default class ScrollTarget {
    /**
     * @desc Set up listener to activate components as they scroll into viewport.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */
    constructor(element) {
        this.element = element
        if (this.enableScrollTarget()) {
            this.observer = this.registerObserver()
            this.observer.observe(this.element)
        } else {
            this.activateElement(this.element)

            const classList = this.element.classList
            classList.forEach((className) => {
                if (className.endsWith(GLOBAL_CONSTANTS.CLASSES.BG_COLOR_SUFFIX)) {
                    classList.remove(className)
                }
            })
        }
    }

    /**
     * @desc Checks if the user agent is Hotjar and disables the scroll target
     * @returns {boolean} - Whether or not to enable the scroll target
     */
    enableScrollTarget() {
        if (navigator.userAgent.match(GLOBAL_CONSTANTS.HOTJAR_AGENT)) {
            return false
        }
        return true
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     * @param {HTMLElement} el - Image element
     */
    registerObserver() {
        const customMargins = this.element.dataset
        const enter = customMargins.enter ? customMargins.enter : '-25%'
        const mobileEnter = customMargins.mobileEnter ? customMargins.mobileEnter : '-25%'
        const leave = customMargins.leave ? customMargins.leave : '0px'
        const options = {
            root: null,
            rootMargin: `${leave} 0px ${isTablet() ? mobileEnter : enter } 0px`, // trigger element when it's 1/4 of the way into the viewport
            threshold: 0.0
        }

        return new IntersectionObserver(this.elementInView.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the image is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    elementInView(elements) {
        elements.forEach(elem => {
            const isTableTop = elem.target.classList.contains(CLASSES.TABLETOP)
            const isImageDrawer = elem.target.classList.contains(CLASSES.IMAGE_DRAWER)

            if (elem.intersectionRatio > 0) {
                this.activateElement(elem.target)
                if (isTableTop) {
                    Emitter.emit(GLOBAL_CONSTANTS.EVENTS.SHOW_TABLE_TOP)
                }
                if (isImageDrawer) {
                    Emitter.emit(GLOBAL_CONSTANTS.EVENTS.GET_IMAGE_DRAWER_HEIGHT)
                }
            } else {
                if (!isTableTop) {
                    this.deactivateElement(elem.target)
                }
                if (isImageDrawer) {
                    Emitter.emit(GLOBAL_CONSTANTS.EVENTS.RESET_IMAGE_DRAWER_HEIGHT)
                }
            }
        })
    }

    /**
     * @desc If the element is in the viewport, add active class
     * @param {HTMLElement} element - The element that is in the viewport
     */
    activateElement(elem) {
        elem.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    /**
     * @desc If the element is out of the viewport, remove active class
     * @param {HTMLElement} element - The element that is out of the viewport
     */
    deactivateElement(elem) {
        elem.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ScrollTargetComponent = {
    'name': 'ScrollTarget',
    'class': SELECTORS.COMPONENT,
    'Source': ScrollTarget
}
