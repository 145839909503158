import { Common, Engine, Render, Runner, World, Body, Bodies, Composite, Events, Mouse, MouseConstraint } from 'matter-js'
import { throttle } from 'throttle-debounce'

import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'
import { preventScroll, allowScroll } from 'utils/scrollUtil'

let maxW = window.innerWidth
let maxH = window.innerHeight

const random = (min, max) => Common.random(min, max)
const posNeg = () => (Math.random() < 0.5 ? -1 : 1)

const isDesktop = maxW > GLOBAL_CONSTANTS.BREAKPOINTS.TABLET_LG

const superballSize = isDesktop ? 130 : 80
const superballMass = isDesktop ? 5 : 8
const superballImgSize = 300

const collisionCategoryBottom = 0x0001
const collisionCategoryTop = 0x0002

const CONFIG = {
    TIMEOUTS: {
        showContent: 8000,
        fadeOutContent: 1000
    },
    SUPERBALL: {
        count: 8,
        size: superballSize,
        vector: {
            x: {
                min: 0.3,
                max: 0.55
            },
            y: {
                min: 0.2,
                max: 0.35
            }
        },
        options: {
            start: {
                restitution: 0.88,
                frictionAir: 0,
                friction: 0,
                frictionStatic: 0,
                mass: superballMass
            },
            end: {
                restitution: 0.6,
                frictionAir: 0.005,
                friction: 0.005,
                frictionStatic: 0.005,
                mass: 1
            }
        }
    },
    MOUSE_CONSTRAINT: {
        stiffness: 0.2,
        render: {
            visible: false
        }
    }
}

const CLASSES = {
    NAV: '.js-navigation',
    COMPONENT: '.js-easter-egg',
    CANVAS: '.js-easter-egg-canvas',
    TRIGGER: '.js-easter-egg-trigger',
    CLOSE: '.js-easter-egg-close',
    CONTENT: '.js-easter-egg-content',
    IMAGES: '.js-easter-egg-images img'
}

export default class EasterEgg {
    /**
     * @desc Set up easter egg with elements and bind events.
     * @param {HTMLElement} el - Element that contains easter egg trigger
     *
     */

    constructor(element) {
        this.el = element
        this.trigger = document.querySelector(CLASSES.TRIGGER)
        this.nav = document.querySelector(CLASSES.NAV)
        this.canvas = this.el.querySelector(CLASSES.CANVAS)
        this.staticUrl = this.el.dataset.static
        this.close = this.el.querySelector(CLASSES.CLOSE)
        this.content = this.el.querySelector(CLASSES.CONTENT)
        this.images = Array.from(this.el.querySelectorAll(CLASSES.IMAGES))
        this.canAddSuperballs = true

        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }

    /**
     * @desc initialize matter.js environment
     */
    initMatter() {
        // engine
        this.engine = Engine.create()
        this.world = this.engine.world

        // renderer
        this.render = Render.create({
            element: this.el,
            engine: this.engine,
            canvas: this.canvas,
            options: {
                width: window.innerWidth,
                height: window.innerHeight,
                background: 'transparent',
                wireframes: false
            }
        })
        Render.run(this.render)

        // runner
        this.runner = Runner.create()
        Runner.run(this.runner, this.engine)

        // add items
        this.addSuperballs()
        this.addWalls()
    }

    /**
     * @desc add superball "bodies" to matter scene
     */
    addSuperballs() {
        this.superballs = []
        this.superballTimeouts = []

        for (let index = 0; index < CONFIG.SUPERBALL.count; index++) {
            const superball = this.createSuperball(index)
            this.superballs.push(superball)
        }

        for (let index = 0; index < this.superballs.length; index++) {
            const delay = random(500, 1000)
            const superballTimeout = setTimeout(() => {
                if (!this.canAddSuperballs) {
                    return
                }

                const superball = this.superballs[index]
                Composite.add(this.world, superball)
                const { x, y } = CONFIG.SUPERBALL.vector
                const vector = {
                    x: posNeg() * random(x.min, x.max),
                    y: random(y.min, y.max)
                }
                Body.applyForce(superball, superball.position, vector)
            }, index * delay)
            this.superballTimeouts.push(superballTimeout)
        }

        Events.on(this.engine, 'collisionStart', (event) => {
            const { bodyA, bodyB } = event.pairs.slice()[0]
            if (bodyA.label === 'superball') {
                bodyA.collisionFilter.category = collisionCategoryBottom
            }
            if (bodyB.label === 'superball') {
                bodyB.collisionFilter.category = collisionCategoryBottom
            }
        })
    }

    /**
     * @desc add walls so superballs stay in the viewport
     */
    addWalls() {
        const wallSize = 1000
        const halfWall = wallSize * 0.5
        const halfW = maxW * 0.5
        const halfH = maxH * 0.5
        const top = Bodies.rectangle(halfW, -halfWall, maxW, wallSize, {
            name: 'top',
            collisionFilter: {
                mask: collisionCategoryBottom
            },
            isStatic: true,
            render: {
                visible: false
            }
        })
        const right = Bodies.rectangle(maxW + halfWall, halfH, wallSize, maxH, {
            name: 'right',
            isStatic: true,
            render: {
                visible: false
            }
        })
        const bottom = Bodies.rectangle(
            halfW,
            maxH + halfWall,
            maxW,
            wallSize,
            {
                name: 'bottom',
                isStatic: true,
                render: {
                    visible: false
                }
            }
        )
        const left = Bodies.rectangle(-halfWall, halfH, wallSize, maxH, {
            name: 'left',
            isStatic: true,
            render: {
                visible: false
            }
        })
        this.walls = [top, right, bottom, left]
        Composite.add(this.world, [...this.walls])
    }

    /**
     * @desc creates one superball body
     */
    createSuperball(index) {
        const { size } = CONFIG.SUPERBALL
        const halfSuperball = size * 0.5
        const x = random(halfSuperball, maxW - halfSuperball)
        const y = -random(0, 100)
        const img = this.images[index].src
        return Bodies.circle(x, y, halfSuperball, {
            label: 'superball',
            collisionFilter: {
                category: collisionCategoryTop
            },
            render: {
                sprite: {
                    texture: img,
                    xScale: size / superballImgSize,
                    yScale: size / superballImgSize
                }
            },
            ...CONFIG.SUPERBALL.options.start
        })
    }

    /**
     * @desc handle window resize event
     */
    resize() {
        if (!this.render.canvas) {
            return
        }

        maxW = window.innerWidth
        maxH = window.innerHeight
        this.render.bounds.max.x = maxW
        this.render.bounds.max.y = maxH
        this.render.options.width = maxW
        this.render.options.height = maxH
        this.render.canvas.width = maxW
        this.render.canvas.height = maxH
        this.walls.map((wall) => Composite.remove(this.world, wall))
        this.addWalls()
    }

    /**
     * @desc reset matter environment
     */
    reset() {
        // update el classes
        this.content.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.el.classList.remove(
            GLOBAL_CONSTANTS.CLASSES.FADE,
            GLOBAL_CONSTANTS.CLASSES.ACTIVE
        )

        // clear & stop running matter instances
        World.clear(this.world)
        Engine.clear(this.engine)
        Render.stop(this.render)
        Runner.stop(this.runner)
        this.render.canvas.remove()
        this.render.canvas = null
        this.render.context = null
        this.render.textures = {}

        // reset superballs and allow them to be added
        this.superballs = []
        for (const superballTimeout of this.superballTimeouts) {
            clearTimeout(superballTimeout)
        }
    }

    /**
     * @desc add gravity back in
     */
    addGravity() {
        for (let index = 0; index < this.superballs.length; index++) {
            const superball = this.superballs[index]
            const { restitution, friction, frictionAir, frictionStatic, mass } =
                CONFIG.SUPERBALL.options.end
            superball.restitution = restitution
            superball.friction = friction
            superball.frictionAir = frictionAir
            superball.frictionStatic = frictionStatic
            Body.setDensity(superball, mass)
        }
    }

    /**
     * @desc delayed gravity after superballs bounce around for a while
     */
    addGravityDelay() {
        this.addGravityTimeout = setTimeout(() => {
            this.addGravity()
        }, this.superballs.length * 1000)
    }

    /**
     * @desc handle superball mouse events
     */
    addMouseConstraints() {
        const mouse = Mouse.create(this.render.canvas)
        const mouseConstraint = MouseConstraint.create(this.engine, {
            mouse,
            constraint: CONFIG.MOUSE_CONSTRAINT
        })

        Composite.add(this.world, mouseConstraint)
        this.render.mouse = mouse
    }

    /**
     * @desc Listen for button click
     */
    registerEvents() {
        // clicks
        this.trigger.addEventListener('click', this.handleOpenOrClose.bind(this))
        this.close.addEventListener('click', this.handleOpenOrClose.bind(this))
    }

    /**
     * @desc Handle open/close of easter egg
     */
    handleOpenOrClose(e) {
        e.preventDefault()
        if (this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            this.hide()
        } else {
            this.show()
        }
    }

    /**
     * @desc hide easter egg
     */
    hide() {
        this.nav.classList.remove(GLOBAL_CONSTANTS.CLASSES.FIXED)
        allowScroll()

        // imediately stop new superballs from being added
        this.canAddSuperballs = false

        // update el classes
        this.trigger.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.close.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)

        // make superballs "fall" and remove listeners
        this.addGravity()
        this.tearDown()

        // make sure content doesn't show & stop gravity
        clearTimeout(this.showContentTimeout)
        clearTimeout(this.addGravityTimeout)

        // wait for superballs to fall, then fade out
        setTimeout(() => {
            this.content.classList.remove(GLOBAL_CONSTANTS.CLASSES.FADE)
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.FADE)
        }, CONFIG.TIMEOUTS.fadeOutContent)

        // finally, hide container & reset
        setTimeout(() => {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.reset()
        }, CONFIG.TIMEOUTS.fadeOutContent + 1500)
    }

    /**
     * @desc show easter egg
     */
    show() {
        this.nav.classList.add(GLOBAL_CONSTANTS.CLASSES.FIXED)
        preventScroll()

        // allow new superballs to be added
        this.canAddSuperballs = true

        // update el classes
        this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.close.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.trigger.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)

        // init matter
        this.initMatter()
        this.addMouseConstraints()
        this.addGravityDelay()

        // show content on delay
        this.showContentTimeout = setTimeout(() => {
            this.content.classList.add(GLOBAL_CONSTANTS.CLASSES.FADE)
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.FADE)
        }, CONFIG.TIMEOUTS.showContent)

        // call resize to fit walls to current screen & add window resize listener
        this.resize.bind(this)
        Emitter.on(
            GLOBAL_CONSTANTS.EVENTS.RESIZE,
            throttle(GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE, this.resize.bind(this))
        )
    }

    /**
     * @desc remove floor so superballs fall off screen
     */
    removeFloor() {
        const floor = this.walls.filter((wall) => wall.name === 'bottom')
        Composite.remove(this.world, floor)
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        // remove "floor" so superballs fall off screen
        this.removeFloor()

        // remove event listeners
        this.el.removeEventListener('click', this.handleClick)
        Emitter.removeListener(
            GLOBAL_CONSTANTS.EVENTS.RESIZE,
            this.resize
        )
    }
}

/**
 * @desc EasterEgg component definition used in module-loader
 */

export const EasterEggComponent = {
    'name': 'EasterEgg',
    'class': CLASSES.COMPONENT,
    'Source': EasterEgg
}
