import Swiper, { Navigation, Pagination, A11y, Keyboard } from 'swiper'
import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    CAROUSEL: '.js-carousel-general',
    NAVIGATION_PREV: '.js-swiper-button-prev',
    NAVIGATION_NEXT: '.js-swiper-button-next',
    PAGINATION: '.js-swiper-pagination',
    OPENER: '.js-artifact-carousel-opener',
    CAROUSEL_ARROW: '.js-carousel-arrow',
    CURSOR_NAVIGATION_PREV: '.carousel__hover-left',
    CURSOR_NAVIGATION_NEXT: '.carousel__hover-right'
}

export default class CarouselGeneral {

    constructor(element) {
        this.element = element
        this.elementData = this.element.dataset
        this.isThreeItem = this.elementData.threeitem ? JSON.parse(this.elementData.threeitem) : false
        this.isTableTop = this.elementData.tabletop ? JSON.parse(this.elementData.tabletop) : false
        this.isArtifactCarousel = this.elementData.artifact ? JSON.parse(this.elementData.artifact) : false
        this.artifactOpener = Array.from(document.body.querySelectorAll(CLASSES.OPENER))
        this.carouselArrow = Array.from(this.element.querySelectorAll(CLASSES.CAROUSEL_ARROW))
        this.initialSlide = 0
        this.params = {}

        // configure Swiper to use modules
        Swiper.use([Navigation, Pagination, A11y, Keyboard])
        this.init()
    }

    init() {
        this.artifactOpener.forEach((opener) => {
            opener.addEventListener('click', (e) => this.openCarouselAtSlide(e))
        })
        if (this.isThreeItem) {
            this.params = {
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                speed: 400,
                draggable: true,
                loop: false,
                slidesPerView: 1.2,
                grabCursor: true,
                watchSlidesVisibility: true,
                centeredSlides: false,
                spaceBetween: 24
            }
        } else if (this.isArtifactCarousel) {
            this.params = {
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                speed: 400,
                draggable: true,
                loop: true,
                initialSlide: this.initialSlide || 0,
                slidesPerView: 1,
                grabCursor: false,
                watchSlidesVisibility: true,
                centeredSlides: true,
                spaceBetween: 32,
                navigation: {
                    nextEl: CLASSES.NAVIGATION_NEXT,
                    prevEl: CLASSES.NAVIGATION_PREV
                },
                pagination: {
                    el: CLASSES.PAGINATION,
                    type: 'fraction'
                }
            }
        } else if (this.isTableTop) {
            this.params = {
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                speed: 400,
                draggable: true,
                loop: true,
                slidesPerView: 1.2,
                grabCursor: true,
                roundLengths: true,
                watchSlidesVisibility: true,
                slideToClickedSlide: true,
                centeredSlides: true,
                spaceBetween: 32
            }
        }
        else {
            this.params = {
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                speed: 400,
                draggable: true,
                loop: true,
                grabCursor: true,
                watchSlidesVisibility: true,
                centeredSlides: true,
                breakpoints: {
                    320: {
                        slidesPerView: 1.3,
                        spaceBetween: 24
                    },
                    768: {
                        slidesPerView: 1.2,
                        spaceBetween: 32
                    }
                },
                navigation: {
                    nextEl: CLASSES.CURSOR_NAVIGATION_NEXT,
                    prevEl: CLASSES.CURSOR_NAVIGATION_PREV
                },
                on: {
                    slideChange: (swiper) => {
                        // ensure previous index is correct. This is a hack
                        // due to swiper adding additional slides when loop is chosen
                        let previousIndex = swiper.previousIndex - 2
                        if ( previousIndex === -1 ) {
                            previousIndex = 0
                        }

                        // swap out the arrow styles on slide change
                        this.carouselArrow.forEach((arrow) => {
                            arrow.classList.remove(`${GLOBAL_CONSTANTS.CLASSES.ACTIVE}-${previousIndex}`)
                            arrow.classList.add(`${GLOBAL_CONSTANTS.CLASSES.ACTIVE}-${swiper.realIndex}`)
                        })
                    }
                }
            }
        }

        this.swiper = new Swiper(this.element, this.params)
    }

    openCarouselAtSlide(e) {
        e.preventDefault()
        const initialSlide = e.currentTarget.dataset.initialslide
        this.swiper.slideTo(initialSlide, false,false)
    }
}

export const CarouselGeneralComponent = {
    'name': 'CarouselGeneral',
    'class': CLASSES.CAROUSEL,
    'Source': CarouselGeneral
}
