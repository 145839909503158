import { GLOBAL_CONSTANTS } from 'utils/constants'
import Emitter from 'utils/emitter'

const CLASSES = {
    COMPONENT: '.js-featured-content-fullbleed',
    BOX: '.js-content-box'
}

export default class FeaturedContentFullBleed {
    constructor(element) {
        this.element = element
        this.contentBox = element.querySelector(CLASSES.BOX)
        this.init()
        this.handleResize()
    }

    init() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize.bind(this))
    }

    handleResize() {}
}

export const FeaturedContentFullBleedComponent = {
    'name': 'FeaturedContentFullBleed',
    'class': CLASSES.COMPONENT,
    'Source': FeaturedContentFullBleed
}