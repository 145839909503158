import MicroModal from 'utils/MicroModal'

const CLASSES = {
    COMPONENT: '.js-modal'
}

export default class Modal {
    constructor(el) {
        this.el = el
        this.init()
    }

    init() {
        this.triggers = document.querySelectorAll(`[data-micromodal-trigger=${this.el.id}]`)

        const startOpen = this.el.dataset.startOpen || false

        this.modal = new MicroModal({
            disableScroll: true,
            disableFocus: false,
            awaitCloseAnimation: true,
            targetModal: this.el.id,
            triggers: this.triggers,
            startOpen: startOpen
        })
    }
}

export const ModalComponent = {
    'name': 'Modal',
    'class': CLASSES.COMPONENT,
    'Source': Modal
}
