import { debounce } from 'throttle-debounce'
import Emitter from 'utils/emitter'

import { GLOBAL_CONSTANTS, isStandardTablet } from 'utils/constants'
import MasonryDesktop from './MasonryDesktop'
import MasonryMobile from './MasonryMobile'

const CLASSES = {
    COMPONENT: '.js-collection-page'
}

export default class Masonry {
    constructor() {
        this.masonry = null
        this.isMobile = isStandardTablet()

        this.registerEvents()
        this.initMasonry()
    }

    registerEvents() {
        this.debounceResize = debounce(GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE, this.resize.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.debounceResize)
    }

    resize() {
        if (this.isMobile !== isStandardTablet()) {
            this.isMobile = !this.isMobile
            return this.initMasonry()
        }
    }

    initMasonry() {
        if (this.isMobile) {
            this.masonry = new MasonryMobile()
        } else {
            this.masonry = new MasonryDesktop()
        }
    }
}

export const MasonryComponent = {
    'name': 'Masonry',
    'class': CLASSES.COMPONENT,
    'Source': Masonry
}
