const CLASSES = {
    NO_SCROLL: 'no-scroll'
}

const CUSTOM_PROPERTIES = {
    SCROLLBAR_WIDTH: '--scrollbar--width'
}

export function preventScroll() {
    document.documentElement.classList.add(CLASSES.NO_SCROLL)
}

export function allowScroll() {
    document.documentElement.classList.remove(CLASSES.NO_SCROLL)
}

export function getScrollbarWidth() {
    const html = document.documentElement
    const scrollElement = document.createElement('div')
    scrollElement.style.cssText = 'position: fixed; top: 0; width: 100%; height: 200vh;'
    html.style.setProperty('overflow-y', ' scroll')
    html.insertBefore(scrollElement, html.firstChild)
    const scrollbarWidth = window.innerWidth - scrollElement.offsetWidth
    html.removeChild(scrollElement)
    html.style.removeProperty('overflow-y')
    if (scrollbarWidth) {
        html.style.setProperty(CUSTOM_PROPERTIES.SCROLLBAR_WIDTH, ` ${scrollbarWidth}px`)
    }
}
