import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-post-card-link',
    IMAGE: '.js-post-card-image',
    WRAPPER: '.js-post-card__image-wrapper',
    BOTTOM_CLASS: '.post-card__image--bottom',
    SKEW: '.js-post-card__image-skew'
}

export default class PostCard {
    /**
     * @desc Set up see more with elements and bind events.
     * @param {HTMLElement} el - Element that contains angles
     *
     */

    constructor(element) {
        this.el = element
        this.image = this.el.querySelector(CLASSES.IMAGE)
        this.bottomClass = this.el.querySelector(CLASSES.BOTTOM_CLASS)
        this.wrapper = this.el.querySelector(CLASSES.WRAPPER)
        this.skew = this.el.querySelector(CLASSES.SKEW)
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
        this.setAngleSize()
    }

    /**
     * @desc Listen for resize
     */
    registerEvents () {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.setAngleSize.bind(this))
    }

    setAngleSize() {
        const isLeftSkew = this.bottomClass ? false : true
        const wrapperPosition = this.wrapper.getBoundingClientRect()
        const imagePosition = this.image.getBoundingClientRect()

        const distance = {
            top: wrapperPosition.top - imagePosition.top,
            right: wrapperPosition.right - imagePosition.right,
            bottom: wrapperPosition.bottom - imagePosition.bottom,
            left: wrapperPosition.left - imagePosition.left
        }

        //we only need to calc box distances for the left skew, right hand side can be done easily via scss
        if (isLeftSkew) {
            this.skew.style.right = `${distance.right}px`
            this.skew.style.bottom = `${distance.bottom}px`
        }
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const PostCardComponent = {
    'name': 'PostCard',
    'class': CLASSES.COMPONENT,
    'Source': PostCard
}
