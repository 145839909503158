import ImageZoom from 'utils/ImageZoom'
import TooltipCursor from 'utils/TooltipCursor'

const SELECTORS = {
    COMPONENT: '.js-custom-cursor'
}

export default class CustomCursor {
    constructor(element) {
        this.element = element
        this.cursor = element.dataset.cursor

        this.init(this.cursor)
    }

    init(cursor) {
        if (cursor === 'zoom') {
            this.imageZoom = new ImageZoom(this.element)
        }
        if (cursor === 'tooltip') {
            this.tooltipCursor = new TooltipCursor(this.element)
        }
    }
}

export const CustomCursorComponent = {
    'name': 'CustomCursor',
    'class': SELECTORS.COMPONENT,
    'Source': CustomCursor
}