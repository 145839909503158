import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-see-more',
    BUTTON: '.js-see-more-button',
    SECTION: '.js-see-more-section'
}

export default class SeeMore {
    /**
     * @desc Set up see more with elements and bind events.
     * @param {HTMLElement} el - Element that contains button and section
     *
     */

    constructor(element) {
        this.el = element
        this.button = this.el.querySelector(CLASSES.BUTTON)
        this.section = this.el.querySelector(CLASSES.SECTION)
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }

    /**
     * @desc Listen for button click
     */
    registerEvents () {
        this.button.addEventListener('click', this.handleClick.bind(this))
    }

    /**
     * @desc Handle click of see more button by displaying section
     */
    handleClick (e) {
        e.preventDefault()
        this.button.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.section.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown () {
        this.button.removeEventListener('click', this.handleClick)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const SeeMoreComponent = {
    'name': 'SeeMore',
    'class': CLASSES.COMPONENT,
    'Source': SeeMore
}
