import { isTouchDevice } from 'utils/constants'
import { GLOBAL_CONSTANTS } from './constants'

const SELECTORS = {
    TOOLTIP: '.js-tooltip'
}

export default class TooltipCursor {
    constructor(element) {
        this.container = element
        this.parent = this.container.parentElement
        this.tooltip = this.parent.querySelector(SELECTORS.TOOLTIP)

        this.init()
    }

    init() {
        this.registerEvents()
    }

    registerEvents() {
        if (isTouchDevice()) {
            this.container.addEventListener('click', this.handleClick.bind(this))
        } else {
            this.container.parentElement.addEventListener('mousemove', this.handleMousemove.bind(this))
            this.container.parentElement.addEventListener('mouseleave', this.handleMouseleave.bind(this))
        }
    }

    handleMousemove(e) {
        let left = e.clientX - 5
        let top = e.clientY + 5
        this.tooltip.style.left = left + 'px'
        this.tooltip.style.top = top + 'px'
    }

    handleMouseleave() {
        this.tooltip.style.left = 0
        this.tooltip.style.top = 0
    }

    handleClick() {
        if (this.tooltip.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            this.tooltip.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            this.tooltip.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }
}